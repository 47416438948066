.about-page header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(../fotos/img/about-chef1.jpg);
    background-size: cover;
    background-position: center;
    height: 50vh; 
}

.slide-in-down {
    animation: slideInDown 1s ease-out;
}

.slide-in-top {
    animation: slideInTop 1s ease-out;
}

.about-page .whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 100;
    display: flex;
    align-items: center;
}

.about-page .whatsapp-icon {
    font-size: 24px;
    margin-left: 10px;
    z-index: 100;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.welcome-message {
    animation: fadeOut 1s ease-in-out 5s forwards;
}



@media(max-width: 475px) {

    .about-page .container {
        padding: 0 15px;
    }

    .about-page header {
        margin-bottom: 20px;
        text-align: center;
    }

    .about-page .container p {
        font-size: 0.9rem;
    }

    .about-page .row {
        flex-direction: column;
        gap: 15px;
    }

    .about-page .col-lg-6 {
        width: 100%;
        padding: 0 15px;
        text-align: center;
        margin-bottom: 15px;
    }

    .about-page img {
        width: 100%;
        height: auto;
    }

    .about-page header h1 {
        position: relative;
        font-size: 1.8rem;
        text-align: center;
        top: 50px;
    }

    .about-page .ratio {
        height: 390px; 
    }

    .about-page .whatsapp-container {
        bottom: 235px;
         right: 10px; 
     }
 
     .about-page .welcome-message {
         font-size: 8px; 
     }
 
     .about-page .whatsapp-icon {
         font-size: 17px;
     }
 
}