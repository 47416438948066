.home-page header {
    background-image:
        url(../fotos/img/About-chef4.jpg);
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
}

h1, h2, h3 {
    font-family: "Sofia Sans", sans-serif;
}

header .carousel-caption .caption-background h1 {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    position: relative;
    line-height: 75px;
    bottom: 220px;
    font-size: 5rem;
    
}

header .carousel-caption .caption-background .initial-message p {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    position: relative;
    bottom: 220px;
    font-size: 1.3rem;
   
}

header .carousel-caption .caption-background h3 {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    position: relative;
    line-height: 75px;
    bottom: 145px;
    font-size: 3rem;
   
}

header .carousel-caption .caption-background p {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    position: relative;
    bottom: 120px;
    font-size: 1.3rem;
}


header .button-close-spacing {
    position: relative;
    bottom: 7rem;
}

header .slide-fade {
    opacity: 0;
    transform: translateY(20%);
}

header .slide-fade-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
}

header .slide-fade-exit {
    opacity: 1;
    transform: translateY(0);
}

header .slide-fade-exit-active {
    opacity: 0;
    transform: translateY(-20%);
    transition: opacity 500ms, transform 500ms;
}

.home-page .col-lg h2 {
    margin-top: 50px;
}


.home-page .col-h2 {
    position: relative;
    top: 70px;
}

.home-page .carousel-inner .carousel-item img {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
    transition: opacity 1s ease, transform 0.8s ease;
}



.home-page .col-lg button {
    margin-bottom: 40px;
}

.shrimp-img {
    width: 160px;
    height: 40px;
    position: relative;
    left: 15%;
    top: 15px;
}

.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    display: flex;
    align-items: center;
}

.whatsapp-icon {
    font-size: 24px;
    margin-left: 10px;
    z-index: 100;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.welcome-message {
    font-size: 14px;
    animation: fadeOut 1s ease-in-out 5s forwards;
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-left {
    animation: slideInLeft 1s ease-out;
}

.slide-in-left:nth-child(2) {
    animation-delay: 0.3s;
}


.menu-section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../fotos/img/menu-img.jpg);
    background-size: cover;
    background-position: center;
}

.img {
    border-style: solid;
    border-width: 5px;
}

.home-page .carousel-item img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}


@media (max-width: 768px) {

    header .carousel-caption .caption-background h1 {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        line-height: 1.1;
        white-space: pre-line;
        left: 10px;
        font-size: 2.5rem;
    }

    header .carousel-caption .caption-background p {
        bottom: 170px;
        left: 10px;
        font-size: 1rem;
    }

    header .caption-background .reservation-message h3 {
        position: relative;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        bottom: 155px;
        line-height: 2rem;
        font-size: 2rem;
    }

    header .caption-background .reservation-message p {
        position: relative;
        bottom: 140px;
        text-align: center;
        font-size: 1rem;
    }

    header .caption-background .menu-message h3 {
        position: relative;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        line-height: 2rem;
        bottom: 150px;
        font-size: 2rem;
    }

    header .caption-background .menu-message p {
        position: relative;
        bottom: 135px;
        text-align: center;
        font-size: 1rem;
    }

    header .caption-background .button-close-spacing {
        position: relative;
        bottom: 140px;
        padding: 0.5rem 1rem !important;
        font-size: 1rem;
    }

    .home-page .item img {
        height: 200px;
        object-fit: cover;
    }

    .home-page .container h2 {
        position: relative;
        top: 20px;
    }

    .home-page .col-12 h2 {
        position: relative;
        font-size: 1.4rem;
        margin-bottom: 25px;
    }
}

@media (max-width: 475px) {

    .home-page header {
        text-align: center;
        padding: 20px;
    }

    header .caption-background .initial-message h1 {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        margin-bottom: 290px;
        font-size: 2.5rem;
        line-height: 1.2;
        white-space: pre-line;
        text-align: center;
    }

    header .caption-background .initial-message p {
        position: relative;
        bottom: 250px !important;
        text-align: center;
        font-size: 1rem;
    }

    header .caption-background .initial-message {
        padding: 1rem;
        animation: fadeInUp 1s ease-out;
    }

    header .caption-background .reservation-message h3 {
        position: relative;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        margin-bottom: 90px;
        line-height: 2.5rem;
        font-size: 2.3rem;
    }

    header .caption-background .menu-message h3 {
        position: relative;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        margin-bottom: 90px;
        line-height: 2.3rem;
        font-size: 1.8rem;
    }

    header .caption-background .reservation-message p {
        position: relative;
        bottom: 140px;
        text-align: center;
        font-size: 1rem;
    }

    header .caption-background .menu-message p {
        position: relative;
        bottom: 140px;
        text-align: center;
        font-size: 1rem;
    }

    header .caption-background .button-close-spacing {
        position: relative;
        bottom: 230px;
        padding: 0.5rem 1rem !important;
        font-size: 1rem;
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .home-page header h1 {
        font-size: 2rem;
        line-height: 30px;
        position: relative;
        top: 30px;
        right: 85px;
    }

    .home-page header h2 {
        font-size: 1.4rem;
        position: relative;
        right: 30px;
        top: 40px;
    }

    .home-page .shrimp-img {
        position: relative;
        width: 75px;
        height: 23px;
        margin-right: 90%;
        top: 35px;

    }

    .world-text {
        font-size: 1.9rem;
    }

    .shrimp-text {
        font-size: 2.2rem;
    }

    .home-page .container {
        padding: 0 10px;
    }

    /* Sobre nós */
    .home-page .col-lg.d-flex.flex-column {
        text-align: center;
    }

    .home-page .col-lg h2 {
        position: relative;
        font-size: 1.2rem;
        top: 13px;
    }

    .home-page .col-lg p {
        font-size: 0.9rem;
        text-align: left;
    }

    .home-page .carousel-inner .carousel-item img {
        height: 250px;
        max-height: 250px;
        width: 100%;
        object-fit: cover;
    }

    .home-page .col-lg button {
        position: relative;
        bottom: 15px;
    }


    .home-page .col-lg img {
        width: 100%;
        max-width: 250px;
    }

    .home-page .col-lg.d-flex img {
        margin: 0 auto;
    }

    .home-page .col-lg.d-flex button {
        margin-bottom: 20px;
    }

    .home-page .menu-section {
        padding: 20px 0;
    }

    .home-page .menu-section ul {
        padding: 0;
        list-style-type: none;
    }

    .home-page .menu-section h3 {
        font-size: 1.5rem;
    }

    .home-page .menu-section ul li {
        font-size: 1.2rem;
    }

    .home-page .container h2 {
        position: relative;
        top: 20px;
    }

    .home-page .item img {
        height: 330px !important;
        object-fit: cover;
    }

    /* Contato */
    .home-page .bg-dark .row {
        flex-direction: column;
        text-align: center;
    }

    .home-page .bg-dark img {
        width: 100%;
        max-width: 50px;
        margin-bottom: 20px;
    }

    .contact-icons i {
        font-size: 1.6rem;
    }

    .home-page .col-12 h2 {
        position: relative;
        font-size: 1.3rem;
        margin-bottom: 25px;
    }

    .mb-4 .d-flex {
        font-size: 1rem;
    }

    .home-page .ratio {
        height: 390px;
    }

    .home-page .btn {
        width: 100%;

    }

    .home-page .whatsapp-container {
        bottom: 20px;
        right: 20px;
    }

    .home-page .welcome-message {
        font-size: 10px;
    }

    .home-page .whatsapp-icon {
        font-size: 18px;
    }


}