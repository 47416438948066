.menu-page header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(../fotos//img/gallery4.jpg);
    background-size: cover;
    background-position: center;
    height: 50vh;
}

.menu-page .whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    display: flex;
    align-items: center;
}

.category-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.category-item {
    width: 22%;
    margin-bottom: 20px;
}

.category-item img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    aspect-ratio: 16 / 9;
}

.category-button {
    font-size: 1rem;
    padding: 10px;
}

.text-center.mt-4 {
    display: flex;
    justify-content: center;
    align-items: center;
}


.menu-page .whatsapp-icon {
    font-size: 24px;
    margin-left: 10px;
    z-index: 100;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.welcome-message {
    animation: fadeOut 1s ease-in-out 5s forwards;
}

@keyframes slideInDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .category-item {
        width: 45%;
        margin-bottom: 15px;
    }
}


@media (max-width: 475px) {

    .menu-page .container {
        padding: 0 15px;
    }

    .menu-page header h1 {
        position: relative;
        font-size: 1.9rem;
        text-align: center;
        top: 50px;
    }

    .menu-page h2 {
        position: relative;
        font-size: 1.6rem;
        text-align: center;
        top: 10px;
    }

    .menu-page .col-lg-6 {
        flex-direction: row;
        gap: 15px;
        font-size: 0.9rem;
    }

    .menu-page .row {
        flex-direction: column;
    }

    .menu-page .card {
        margin-bottom: 10px;
    }

    .menu-page .card-title {
        font-size: 0.7rem;
    }

    .menu-page .card-text {
        font-size: 0.7rem;
    }

    .menu-page .btn-dark {
        font-size: 0.9rem;
        padding: 0.4rem 0.8rem;
        width: 100%;
    }

    .menu-page .img-fluid {
        height: 150px;
    }

    .menu-page .whatsapp-container {
        bottom: 10px;
        right: 10px;
    }

    .menu-page .welcome-message {
        font-size: 8px;
    }

    .menu-page .whatsapp-icon {
        font-size: 18px;
    }

    .menu-page .text-center.mt-4 {
        justify-content: center;
    }

    .menu-page .btn {
        font-size: 1.3rem !important;
        padding: 0.8rem 1.rem !important;
        width: 90%;

    }

    .menu-page .custom-btn {
        font-size: 1.4rem !important;
        padding: 0.8rem 1.rem !important;
    }

    .btn .fab {
        margin-left: 0 !important;
        margin-top: 0.5rem !important;
    }

    .menu-page .qr-code {
        width: 60px;
        height: 60px;
    }

    .menu-page .text-center {
        flex-direction: column;
    }


    .menu-page .text-center {
        font-size: 1rem !important;
    }

    .menu-page .menu {
        font-size: 1.5rem !important;
    }

    .menu-page .menu-name {
        font-size: 1.2rem !important;
    }

    .category-item {
        width: 90%;
    }

    .category-item img {
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
    }

    .category-button {
        font-size: 1rem !important;
        padding: 10px 5px !important;
    }

    .menu-page .list-group-item .d-flex {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .menu-page .list-group-item .d-flex .fs-6 {
        font-size: 1rem;
    }

    .menu-page .list-group-item .fs-6 {
        font-size: 0.9rem;
    }

    .menu-page .list-group-item .text-muted {
        font-size: 0.7rem;
        margin-top: 0.2rem;
    }


}